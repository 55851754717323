import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import { StepperInput } from "./StepperInput";
import styled from "styled-components";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
`;

export const StudentRegisterPage = () => {
  const [form] = Form.useForm();
  const [isLoaded, setIsLoaded] = useState(false);
  const [windowsSize, setWindowSize] = useState({ width: 0, height: 0 });
  const [timing, setTiming] = useState("02:00");

  const studentFormStyle =
    windowsSize.width < 577
      ? {
          padding: "10px",
          height: "calc(100vh - 120px)",
          overflow: "auto",
        }
      : {
          padding: "10px",
          overflowY: "auto",
          overflowX: "hidden",
        };

  const fromAlign = {
    span: windowsSize.width < 577 ? 24 : 12,
  };

  const onFinish = async (e) => {
    e = { ...e, subjectsTaken: e.subjectsTaken.join(",") };
    console.log(e);
    const formData = new FormData();
    Object.entries(e).forEach((i) => {
      formData.append(i[0], i[1]);
    });

    try {
      setIsLoaded(true);
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbzn2cFibrM264lREaftdfpMB7yi2svsKYXaa36SwJIP5PdJxqZpr8SlXcRMqyjRXqY_/exec",
        {
          method: "POST",
          body: formData, // FormData object is sent as the body
        }
      );

      const data = await response.json();
      console.log("Server response:", data);
      message.success(`Record Created Successfully with S.No ${data.row}`);
    } catch (err) {
      console.error("Error Message --> ", err, "<---");
      message.error("Failed to upload Data, re connect and try again");
    } finally {
      form.resetFields();
      setIsLoaded(false);
    }
  };

  const onFinishFailed = (err) => {
    console.log(err);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const formValidations = {
    Strings: { required: true, message: "${label} is required" },
    Name: {
      pattern: /^[A-Za-z]+$/,
      message: "Only alphabetic characters allowed",
    },
    Score: {
      pattern: /^(100|[1-9]?\d)$/,
      message: "Enter a valid score between 0 and 100",
    },
    Numbers: {
      pattern: /^[0-9]+$/,
      message: "Only numerical values are allowed",
    },
    PhoneNumbers: {
      pattern: /^[0-9]{10}$/,
      message: "Only numerical values are allowed up to 10",
    },
    Duration: {
      pattern: /^(0?[1-9]|1[0-2]):[0-5][0-9]$/,
      message: "Enter a valid duration hrs, min",
    },
    email: { type: "email", message: "Not a valid email" },
    Password: {
      pattern:
        /^(?=.\d)(?=.[a-z])(?=.[A-Z])(?=.[!@#$%^&.~{}*_=+;:'",</?|>-]).{8,12}$/,
      message:
        " Passwords should contain Numbers, Special Symbols, Uppercase and Lowercase letters ",
    },

    NonNegative: {
      validator(_, value) {
        if (Number(value) >= 1) {
          return Promise.resolve();
        }
        return Promise.reject("Value must be a positive number");
      },
    },
  };

  const optionMasterTypes = {
    boardTypes: ["SSC", "CBSE", "ICSE"],
    studentStatusTypes: ["AWAY", "REGULAR", "IN-ACTIVE"],
    gradeTypes: Array.from(
      { length: 10 },
      (_, index) => "Class-" + String(index + 1)
    ),
    subjects: [
      "All Subjects",
      "Telugu",
      "Hindi",
      "English",
      "Maths",
      "Physics",
      "Chemistry",
      "Biology",
      "Social",
      "Science",
    ],
    filterTypes: [
      "contains",
      "search",
      "none",
      "searchCombined",
      "containsCombined",
    ],
  };

  const arrayOfStringConverter = (item) =>
    typeof item === "undefined"
      ? {
          value: "NA",
          label: "NA",
        }
      : item.map((i) => ({
          value: i,
          label: i,
        }));

  const handleSubjectsChange = (selectedItems) => {
    if (selectedItems.includes("All Subjects")) {
      form.setFieldsValue({
        subjectsTaken: optionMasterTypes.subjects.filter(
          (subject) => subject !== "All Subjects"
        ),
      });
    } else {
      form.setFieldsValue({
        subjectsTaken: selectedItems,
      });
    }
  };

  return (
    <Container>
      <Spin
        spinning={isLoaded}
        tip="Loading..."
        indicator={<LoadingOutlined spin />}
      >
        <Typography.Title
          style={{ margin: "0px", alignItems: "center" }}
          level={4}
        >
          Student Registration
        </Typography.Title>
        <div
          style={{
            width: "85vw",
            height: "75%",
            overflow: "hidden",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
            borderRadius: "15px",
            alignItems: "center",
            backgroundColor: "transparent",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "30px 20px 0px 20px",
          }}
        >
          <Form
            style={studentFormStyle}
            name="newStudent"
            form={form}
            labelWrap
            size={
              windowsSize.width < 577
                ? "small"
                : windowsSize.width > 576 && windowsSize.width < 1440
                ? "middle"
                : "large"
            }
            variant="filled"
            initialValues={{
              boardType: "SSC",
              Status: "REGULAR",
              timing: "02:00",
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            requiredMark={false}
          >
            <Row gutter={windowsSize.width < 577 ? 0 : [30, 5]} align="center">
              <Col {...fromAlign}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[formValidations.Strings]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col {...fromAlign}>
                <Form.Item
                  label="Class No"
                  name="classNo"
                  rules={[formValidations.Strings]}
                >
                  <Select
                    options={arrayOfStringConverter(
                      optionMasterTypes.gradeTypes
                    )}
                  />
                </Form.Item>
              </Col>
              <Col {...fromAlign}>
                <Form.Item
                  label="School Name"
                  name="schoolName"
                  rules={[formValidations.Strings]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col {...fromAlign}>
                <Form.Item
                  label="Board Type"
                  name="boardType"
                  rules={[formValidations.Strings]}
                >
                  <Select
                    options={arrayOfStringConverter(
                      optionMasterTypes.boardTypes
                    )}
                  />
                </Form.Item>
              </Col>
              <Col {...fromAlign}>
                <Form.Item
                  label="Status"
                  name="Status"
                  rules={[formValidations.Strings]}
                >
                  <Select
                    options={arrayOfStringConverter(
                      optionMasterTypes.studentStatusTypes
                    )}
                  />
                </Form.Item>
              </Col>
              <Col {...fromAlign}>
                <Form.Item
                  label="Past Grade"
                  name="pastScore"
                  rules={[formValidations.Strings, formValidations.Score]}
                >
                  <Input suffix="%" />
                </Form.Item>
              </Col>
              <Col {...fromAlign}>
                <Form.Item
                  label="Subjects"
                  name="subjectsTaken"
                  rules={[formValidations.Strings]}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    onChange={handleSubjectsChange}
                    options={arrayOfStringConverter(optionMasterTypes.subjects)}
                  />
                </Form.Item>
              </Col>
              <Col {...fromAlign}>
                <Form.Item
                  label="Timings"
                  name="timing"
                  rules={[formValidations.Strings]}
                >
                  <StepperInput value={timing} onChange={setTiming} />
                </Form.Item>
              </Col>
              <Col {...fromAlign}>
                <Form.Item
                  label="Parent Name"
                  name="parentName"
                  rules={[formValidations.Strings]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col {...fromAlign}>
                <Form.Item
                  label="Parent Phn.No"
                  name="parentPhnNo"
                  rules={[
                    formValidations.Strings,
                    formValidations.PhoneNumbers,
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col {...fromAlign}>
                <Form.Item
                  label="WhatsAppNo"
                  rules={[
                    formValidations.Strings,
                    formValidations.PhoneNumbers,
                  ]}
                  name="WhatsAppNo"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col {...fromAlign}>
                <Form.Item
                  label="Tuition Fee Per Month"
                  name="feeCharge"
                  rules={[formValidations.Strings, formValidations.Numbers]}
                >
                  <Input suffix="₹ /- " />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Row justify="end">
                <Button
                  type="primary"
                  htmlType="reset"
                  ghost
                  danger
                  onClick={() => form.resetFields()}
                  style={{ margin: "0px 10px", width: "20%" }}
                >
                  Reset
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ margin: "0px 10px", width: "20%" }}
                >
                  Submit
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </Container>
  );
};
