import "./App.css";
import { StudentRegisterPage } from "./StudentRegisterPage";

function App() {
  return (
    <div className="App">
      <StudentRegisterPage />
    </div>
  );
}

export default App;
